<template>
  <v-dialog v-model="pixDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>Add/Upload Evidence</v-card-title>
      <v-card-text>
        <v-select
          dense
          label="information Type"
          :items="doctypeList"
          item-value="id"
          item-text="name"
          v-model="add.information_typeid"
        ></v-select>

        <v-text-field
          dense
          label="description"
          v-model="add.description"
        ></v-text-field>

        <v-row>
          <v-col cols="12" sm="10">
            <v-file-input
              label="Upload your picture here"
              clearable
              show-size
              truncate-length="15"
              accept="image/png, image/jpeg"
              @change="changePix"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="2">
            <v-avatar size="65" color="#cfe0f8">
              <v-img :src="add.evidence"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        {{ myPix }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="disabled"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils";
export default {
  mixins: [Utils],
  props: {
    pixDialog: Boolean,
    myId: { type: String },
    name: { type: String },
  },
  data() {
    return {
      loading: false,
      disabled: true,
      myPix: null,
      pix: 0,
      doctypeList: [],
      add: { information_typeid: null, description: null, evidence: null },
    };
  },
  watch: {
    pix: function (a) {
      if (a > 500000) {
        this.myPix = "Picture has to be less than 300KB (0.3MB) in size";
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },
  created() {
    this.infoTypes();
  },
  methods: {
    infoTypes() {
      this.loading = true;
      let self = this;
      Restful.general.infoTypes
        .list()
        .then((response) => {
          console.log(response.data);
          this.doctypeList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    changePix(e) {
      if (e != null) {
        console.log("e:", e);
        console.log("size:", e.size);
        this.pix = e.size;
        this.getPix(e).then((response) => {
          let pix = response;
          this.add.evidence = pix;
          console.log(this.add.fileName);
        });
      }
    },
    closeDialog() {
      let payload = { id: this._decode(this.myId), state: false };
      this.$emit("closePix", payload);
    },
    submit() {
      let medics_available_timesid = this._decode(this.myId);
      let information_typeid = this.add.information_typeid;
      let description = this.add.description;
      let evidence = this.add.evidence;
      let data = {
        medics_available_timesid,
        information_typeid,
        description,
        evidence,
      };
      console.log("data:", data);

      let payload = { id: this._decode(this.myId), data: data, state: false };
      this.$emit("submitPix", payload);
    },
  },
};
</script>
