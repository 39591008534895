<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="pixDialog = true">
          new evidence
        </v-btn>
        <EvidenceNew
          :myId="id"
          :pixDialog="pixDialog"
          @closePix="closePix"
          @submitPix="submitPix"
        />
        <!-- of more medical -->
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="goBack" v-on="on">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar dense flat style="margin-bottom: 7px">
        <v-toolbar-title>
          {{ myDate | myDate }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        {{ _decode(start) | myDateTiming }}
        <span style="text-transform: lowercase; padding: 0px 7px">to</span>
        {{ _decode(end) | myDateTiming }}
      </v-toolbar>

      <v-data-iterator :items="moreList">
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              lg="4"
              md="4"
              sm="4"
            >
              <v-card flat outlined>
                <v-img
                  :src="pix(item.evidence)"
                  :title="item.description"
                  height="350"
                ></v-img>
                <v-card-text class="card_list">
                  <ul>
                    <li>
                      <div class="myleft">information type</div>
                      <div class="myright">
                        {{ truncateString(item.information_typeName, 22) }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">description</div>
                      <div class="myright">
                        {{ truncateString(item.description, 25) }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">submitted</div>
                      <div class="myright">
                        {{ item.created | myDating }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions style="padding: 8px 16px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        icon
                        v-on="on"
                        @click.stop="$set(viewDialog, item.id, true)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View Detail</span>
                  </v-tooltip>
                  <EvidenceView
                    v-if="viewDialog[item.id]"
                    :myId="item.id"
                    :name="item.name"
                    :viewDialog="viewDialog[item.id]"
                    @closeView="closeView"
                  />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        icon
                        v-on="on"
                        @click.stop="$set(editDialog, item.id, true)"
                      >
                        <v-icon>mdi-circle-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>
                  <EvidenceEdit
                    v-if="editDialog[item.id]"
                    :editDialog="editDialog[item.id]"
                    :myId="item.id"
                    @closeEdit="closeEdit"
                    @submitEdit="submitEdit"
                  />
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="error"
                        icon
                        v-on="on"
                        @click.stop="$set(removeDialog, item.id, true)"
                      >
                        <v-icon>mdi-delete-empty-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <EvidenceRemove
                    v-if="removeDialog[item.id]"
                    :myId="item.id"
                    :name="item.name"
                    :removeDialog="removeDialog[item.id]"
                    @closeRemove="closeRemove"
                    @submitRemove="submitRemove"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../mixins/utils";
import WinScroll from "../../mixins/windowscroll";
import EvidenceNew from "../../components/appointments/evidence/newone.vue";
import EvidenceView from "../../components/appointments/evidence/view.vue";
import EvidenceEdit from "../../components/appointments/evidence/edit.vue";
import EvidenceRemove from "../../components/appointments/evidence/remove.vue";

export default {
  props: {
    id: { type: String },
    date: { type: String },
    loc: { type: String },
    start: { type: String },
    end: { type: String },
  },
  mixins: [Util, WinScroll("position")],
  components: { EvidenceNew, EvidenceView, EvidenceEdit, EvidenceRemove },
  data: () => ({
    pixDialog: false,
    viewDialog: {},
    editDialog: {},
    removeDialog: {},
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "patient.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Appointment",
        to: { name: "patient.appointments" },
      },
      {
        exact: true,
        link: true,
        text: "More Information",
        to: { name: "patient.appointments.more" },
      },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    loading: false,
    moreList: [],
  }),
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    myDate: function () {
      let date = this._decode(this.date);
      return date;
    },
  },
  created() {
    this.list();
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    list() {
      this.loading = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.evidence
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.moreList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    pix(img) {
      if (img === "") {
        return "";
      } else {
        return img;
      }
    },
    closePix(p) {
      this.pixDialog = p.state;
    },
    submitPix(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.evidence
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.pixDialog = p.state;
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.evidence
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      Restful.medics.evidence
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
